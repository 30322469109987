import cellsimPortalService from '@/api/cellSim.js';

export default {
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
  checkStatus(number, params) {
    return cellsimPortalService
      .Api().get(`serialVault/serialNumber/${number}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  },
};
