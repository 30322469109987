<template>
  <v-container fluid class="touch-view-container">
    <v-card-text style="height: 100%">
      <v-layout align-center fill-height justify-center>
        <v-flex xs12 sm8 md4>
          <div v-if="!status">
            <div class="text-center text-h2">Activation</div>
            <div class="text-center text-h2">Status</div>

            <div class="mt-10">
              <v-row>
                <v-col>
                  <span>Sim Card Number <span class="red--text">*</span></span>
                  <v-text-field v-model="simcard" solo clearable @keydown.enter="submit" :error-messages="simCardErrors"
                    :hide-details="simCardErrors.length === 0" autofocus label="Sim Card Number"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn block dark @click.stop.prevent="$router.go(-1)" color="secondary" large>Back</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn block dark color="primary" :loading="loading" @click="submit" large>Submit</v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-else>
            <div class="text-center" v-if="status.status === 'activate' || status.status === 'portIn'">
              <div class="cong text-h3 font-weight-black">Processing</div>
              <lottie :height="100" :options="defaultWatingOptions" name="receiptCheck"
                v-on:animCreated="handleAnimation" />
              <div class="mt-4">
                <span class="font-weight-medium">Sim Number: </span>
                <span>{{ status.serialNumber }}</span>
              </div>
              <div v-if="status && status.Product">
                <span class="font-weight-medium">Name: </span>
                <span>{{ status.Product.name }}</span>
              </div>
              <div v-if="status.metadata && status.metadata.simInfo">
                <div>
                  <span class="font-weight-medium">Activation: </span>
                  <span>{{ status.metadata.simInfo.activation }}</span>
                </div>
                <div>
                  <span class="font-weight-medium">Pin Number: </span>
                  <span>{{ status.metadata.simInfo.pinNumber }}</span>
                </div>
                <div>
                  <span class="font-weight-medium">Plan: </span>
                  <span>{{ status.metadata.simInfo.plan }}</span>
                </div>
              </div>

              <div class="mt-5">
                <span class="font-weight-medium">Your sim will be activated shortly, Check status by selecting
                  the button on the upper right corner of your screen</span>
              </div>
              <div>
                if you find this messages as an error please contact us:
                <a :href="getContactNumber.mailto" class="text-info">
                  <b>{{ getContactNumber.email }}</b>
                </a>
              </div>
            </div>

            <div class="text-center" v-if="status.status === 'activated'">
              <div class="cong text-h3 font-weight-black">Congratulation</div>
              <lottie :height="100" :options="defaultApprovedOptions" name="receiptCheck"
                v-on:animCreated="handleAnimation" />
              <v-card class="mt-5 rounded-lg" outlined>
                <v-card-text class="text-left">
                  <v-row justify="space-between">
                    <v-col cols="4">
                      <div class="font-weight-medium">Sim Number:</div>
                      <div v-if="status.Product" class="font-weight-medium">
                        Name:
                      </div>
                      <div class="font-weight-medium">Pin Number:</div>
                      <div class="font-weight-medium">Status:</div>
                      <div v-if="status.metadata && status.metadata.activated" class="font-weight-medium">
                        Phone Number:
                      </div>
                      <div class="font-weight-medium">Selected Plan:</div>
                    </v-col>
                    <v-col>
                      <div>{{ status.serialNumber }}</div>
                      <div v-if="status.Product">{{ status.Product.name }}</div>
                      <div>{{ status.metadata.simInfo.pinNumber }}</div>
                      <div>Activated</div>
                      <div v-if="status.metadata && status.metadata.activated">
                        {{ status.metadata.activated.phoneNumber }}
                      </div>
                      <div>{{ status.metadata.simInfo.plan }}</div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>

            <div v-if="status.status === 'available'">
              <div class="text-center text-h2">Sim Not Found</div>
            </div>

            <v-row class="mt-10" justify="center">
              <v-col class="shrink">
                <v-btn color="secondary" to="/">Home</v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn color="warning" @click="reset">Check Another</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-container>
</template>

<script>
import statusService from "@/modules/Status/service.js";
import { mapGetters } from "vuex";
const { validationMixin } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");
import Lottie from "@/components/lottie";
import * as animationDataApproved from "@/assets/lottie/1708-success.json";
import * as animationDataWatting from "@/assets/lottie/1177-waiting.json";

export default {
  name: "home",
  data() {
    return {
      defaultWatingOptions: {
        animationData: animationDataWatting.default,
        loop: true,
        autoplay: true,
        // onComplete: this.$emit('close'),
      },
      defaultApprovedOptions: {
        animationData: animationDataApproved.default,
        loop: false,
        autoplay: true,
        // onComplete: this.$emit('close'),
      },
      loading: false,
      simcard: null,
      status: null,
    };
  },
  components: {
    Lottie,
  },
  computed: {
    ...mapGetters("global", ["getContactNumber"]),
    simCardErrors() {
      const errors = [];
      if (!this.$v.simcard.$dirty) return errors;
      if (!this.$v.simcard.required) errors.push("Sim card number is required");
      if (!this.$v.simcard.minLength)
        errors.push("Number must be atleast 18 digits");
      return errors;
    },
  },
  methods: {
    reset() {
      this.status = null;
      this.simcard = null;
      this.$v.$reset();
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false;
      }
      this.loading = true;
      let newsim = this.simcard;
      const lastSimNumber = this.simcard.slice(-1);
      // console.log("lastSimNumber", lastSimNumber);
      if (lastSimNumber === "f" || lastSimNumber === "F") {
      } else {
        newsim += "F";
      }
      return statusService
        .checkStatus(newsim)
        .then((response) => {
          this.loading = false;
          this.status = response.data;
          console.log("result", response);
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.$swal(
            "Sim Status",
            "Invalid sim number please check sim number and try again.",
            "error"
          );
        });
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      simcard: {
        required,
        minLength: minLength(14),
      },
    };
  },
};
</script>

<style scoped>
.card-labels {
  display: block;
}

.touch-view-container {
  height: calc(100vh - 105px);
  width: 100%;
  overflow: auto;
}

.noscroll {
  overflow-y: scroll;
}

div::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.selected {
  background: #0f0c60 !important;
}

.cong {
  color: #0f0c60;
}
</style>

